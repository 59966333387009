<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="indices.columns"
        :items="indices_data"
        :primaryKey="indices.primaryKey"
        :sortBy="indices.sortBy"
        :paginated="false"
        :searchable="false"
      />
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipFluxoCaixaIndices",
  props: {
    idplano_investimento: {
      required: true
    },
    indices_data: {
      type: Array,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "",
        title: "Indicadores de Viabilidade",
        description: `Os indicadores de viabilidade financeira são ferramentas
          utilizadas para fundamentar decisões em relação a investir ou não em
          um projeto. Através desses indicadores é possível reduzir os riscos
          de perder dinheiro, tempo e esforço em algo que terá pouco ou nenhum
          retorno. Os principais indicadores utilizados são: Valor Presente
          Líquido (VPL), Taxa Interna de Retorno (TIR) e Payback ou tempo de
          retorno do capital.`
      },
      indices: {
        columns: [
          {
            key: "descricao",
            label: "Descrição",
            sortable: false
          },
          {
            key: "nome_unidade_medida",
            label: "Unidade",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "valor",
            label: "Valor",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          }
        ],
        primaryKey: null,
        sortBy: ""
      }
    };
  }
};
</script>
