<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="fluxo_columns"
        :items="fluxo_data"
        :primaryKey="fluxo.primaryKey"
        :sortBy="fluxo.sortBy"
        :paginated="false"
        :searchable="false"
      />
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipFluxoCaixaFluxo",
  props: {
    fluxo_data: {
      type: Array,
      required: true
    },
    anos: {
      type: Number,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "",
        title: "Fluxo de Caixa",
        description: `O fluxo de caixa é um dos principais instrumentos da
          análise de viabilidade de uma atividade, pois demonstra a projeção
          de entradas e saídas de recursos financeiros num determinado período
          de tempo. O número de anos utilizado corresponde ao Tempo para Análise
          Econômico-Financeira que você definiu no Cadastro inicial.`
      },
      fluxo: {
        primaryKey: null,
        sortBy: ""
      }
    };
  },
  computed: {
    fluxo_columns() {
      let columns = [
        {
          key: "especificacao",
          label: "Especificação",
          sortable: false,
          tdClass: (value, key, item) => {
            return item.destaque ? "font-weight-bold" : "";
          }
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade",
          sortable: false,
          tdClass: "text-center"
        }
      ];

      for (let ano = 0; ano <= this.anos; ano++) {
        columns.push({
          key: "ano_" + ano,
          label: "Ano " + ano,
          sortable: false,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  }
};
</script>
