<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTEditButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <div v-if="tma">
        <b>Taxa Mínima de Atratividade (TMA)</b>: {{ formatNumber(tma) }}%
      </div>
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipFluxoCaixaTma",
  props: {
    idplano_investimento: {
      required: true
    },
    tma: {
      required: true
    },
    finalizado: {
      type: Boolean,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "",
        title: "Taxa Mínima de Atratividade (TMA)",
        description: `A Taxa Mínima de Atratividade (TMA) é considerada
          uma taxa de referência quando se trata de investimentos financeiros.
          Conhecida também como taxa de desconto ou custo de oportunidade,
          pode ser entendida como o retorno mínimo que o investimento deverá
          produzir para ser atraente. A TMA é comumente empregada para descontar
          o fluxo de caixa, ou seja, trazer a valor presente, deste modo torna-se
          fundamental para estimar parâmetros como o Valor Presente Líquido (VPL).
          Insira a seguir a TMA para o seu projeto. Para ampliar a reflexão e
          assegurar a melhor tomada de decisão, a PAEF indicará uma TMA baseada
          no potencial de risco do projeto. Mostra somente leitura o valor da TMA`
      }
    };
  },
  computed: {
    addRoute() {
      return {
        name: "pip_fluxo_caixa_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  }
};
</script>
