<template>
  <div>
    <KTCard :page="page" class="mb-8" />
    <Tma
      class="mb-8"
      :idplano_investimento="idplano_investimento"
      :tma="tma"
      :finalizado="finalizado"
    />
    <Fluxo class="mb-8" :fluxo_data="tables.fluxo" :anos="anos" />
    <Indices
      :indices_data="tables.indices"
      :idplano_investimento="idplano_investimento"
    />
  </div>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import Indices from "@/view/pages/pip/fluxo_caixa/PipFluxoCaixaIndices.vue";
import Fluxo from "@/view/pages/pip/fluxo_caixa/PipFluxoCaixaFluxo.vue";
import Tma from "@/view/pages/pip/fluxo_caixa/PipFluxoCaixaTma.vue";

export default {
  name: "PipResumoInvestimentoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  components: {
    Indices,
    Fluxo,
    Tma
  },
  data() {
    return {
      page: {
        icon: "la la-tachometer-alt",
        title: "Indicadores de Viabilidade Econômico-Financeira",
        description: `Os indicadores de viabilidade econômico-financeira mensuram
          o quanto uma quantidade de recurso investido em uma determinada atividade
          deverá resultar em um período de tempo estipulado. São importantes para
          subsidiar a tomada de decisão acerca de investimentos em novos projetos
          ou na expansão de um já existente.`
      },
      tables: {
        indices: [],
        fluxo: []
      },
      tma: null,
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/fluxo_caixa`;
    },
    manual() {
      return {
        session: "fluxo_caixa",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.tables.indices = res[R_GETLIST].indices ?? [];
        this.tables.fluxo = res[R_GETLIST].fluxo_caixa ?? [];
        this.tma = res[R_GETLIST].tma;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
